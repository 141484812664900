import React from "react";

const ScreenShotCard = ({ img, link }) => {
  return (
    <a href={link} target="_blank" className="py-5">
      <img
        src={img}
        alt="pic"
        className="max-w-sm md:w-64 w-60 rounded-3xl border-4 h-fit border-white hover:scale-105 my-5 transition-all"
      />
    </a>
  );
};

export default ScreenShotCard;
